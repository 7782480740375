<template>
  <div class="main">
    <h2>{{ $t('msg.promote.title') }}</h2>
    <p class="my-4">{{ $t('msg.promote.subtitle') }}</p>
    <div class="card p-3">
      <div class="row card-body">
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('form') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules">
            <j-field
              prop="type">
              <a-radio-group v-model="form.action">
                <a-radio :style="style" value="HIT">
                  {{ $t('msg.promote.actions.HIT') }}
                </a-radio>
                <a-radio :style="style" value="MISS">
                  {{ $t('msg.promote.actions.MISS') }}
                </a-radio>
              </a-radio-group>
            </j-field>
            <!-- Name -->
            <j-input
              prop="name"
              v-model="form.name"
              icon="user"
              v-if="form.action === 'HIT'" />
            <!-- Identification -->
            <j-input
              prop="identification"
              v-model="form.identification"
              icon="idcard"
              v-if="form.action === 'HIT'" />
            <!-- Issue -->
            <j-enum-select
              prop="issue"
              name="msg.issue"
              v-model="form.issue"
              v-if="form.action === 'MISS'" />
            <!-- Notes -->
            <j-input
              prop="notes"
              v-model="form.notes"
              icon="paper-clip"
              v-if="form.action === 'MISS'" />
          </a-form-model>
          <!-- Submit -->
          <div class="font-size-18 mt-4">
            <a-button
              @click="onSubmit"
              type="primary"
              size="large">
              {{ $t('msg.promote.submit') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lms from '@/api/lms'
import v from '@/utils/validators'
import JField from '@/views/shared/forms/field'
import JInput from '@/views/shared/forms/input'
import JEnumSelect from '@/views/shared/forms/enum-select'
export default {
  components: {
    JField,
    JInput,
    JEnumSelect,
  },
  data: function () {
    return {
      v,
      form: {
        action: 'HIT',
        name: undefined,
        identification: undefined,
        issue: undefined,
        notes: undefined,
      },
      style: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      rules: {
        action: [v.required],
        name: [v.required],
        identification: [v.required, v.number],
        issue: [v.required],
      },
    }
  },
  methods: {
    onSubmit() {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.form.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          const body = {}
          if (this.form.action === 'HIT') {
            body.receiver = {
              name: this.form.name,
              identification: this.form.identification,
            }
          }
          if (this.form.action === 'MISS') {
            body.issue = this.form.issue
            body.notes = this.form.notes
          }
          const code = this.$route.params.shipment
          lms.shipment.promote(code, this.form.action, body)
            .then(() => this.$router.push('detail'))
        }
      })
    },
  },
}
</script>
