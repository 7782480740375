<template>
  <a-form-model-item :prop="prop" :colon="false" :rules="rules" has-feedback>
    <span slot="help" class="text-dark"></span>
    <span slot="label" class="text-dark">{{ label || $t(prop) }}</span>
    <a-input v-model="text" v-bind="$attrs" size="large">
      <a-icon slot="prefix" :type="icon" style="color: rgba(0,0,0,.25)" />
    </a-input>
  </a-form-model-item>
</template>

<script>
export default {
  name: 'JInput',
  props: [
    'value',
    'prop',
    'icon',
    'format',
    'label',
    'rules',
  ],
  computed: {
    text: {
      get() {
        return this.value
      },
      set(value) {
        const formatted = this.format
          ? this.format.format(value)
          : value
        this.$emit('input', formatted)
        this.$emit('change', formatted)
      },
    },
  },
}
</script>
